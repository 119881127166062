import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportImageDisplay } from '../../../../Util/Modals/CustomizableReports/CustomizableReports';
import './TextDoubleIndicator.scss';

const isReportDetail = (forReport: ReportImageDisplay | undefined, baseColor: string) => {
  return forReport && forReport === ReportImageDisplay.Detail ? { color: 'white' } : { color: baseColor };
};

type Props = {
  data: any;
  forReport?: ReportImageDisplay;
};
const TextDoubleIndicator = ({ data, forReport }: Props) => {
  const { t } = useTranslation();
  const { list } = data ? data : [];
  return (
    <div className="text-double-indicator-container">
      {data ? (
        list.map(item => (
          <div className="horizontal" key={item.id}>
            <div className="value">
              {item.value}
            </div>
            <div className="subtitle">
              {t(item.text)}
            </div>
          </div>
        ))
      ) : (
        <div className="empty-data">
          <p className="bold-text">
            -
          </p>
        </div>
      )}
    </div>
  );
};

export { TextDoubleIndicator };
