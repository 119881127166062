import React from 'react';
import { useTranslation } from 'react-i18next';
import './SelectFilters.scss';
import moment from 'moment';

type Props = {
  labelValue: any;
  options?: any;
  disabled?: boolean;
  handleChange?: any;
  showAllOption?: boolean;
  selectValue?: any;
  dataFilter?: any;
  data?: any;
  fnFilterOption?: any;
  fnSetValue?: any;
  detailProject?: boolean;
};
export const SelectFilters = ({
  labelValue,
  options,
  disabled,
  handleChange,
  showAllOption = true,
  selectValue,
  dataFilter,
  data,
  fnFilterOption,
  fnSetValue,
  detailProject = false,
}: Props) => {
  const { t } = useTranslation();

  // funcion para filtrar desde la vista del indicador hitos

  const selectHandler = (event: any) => {
    const value = event.target.value;

    if (value === 'top100') {
      const topFilter = dataFilter.sort(data => data.field3).slice(0, 100);
      handleChange(topFilter);
      fnFilterOption(value);
      fnSetValue('top100');
    } else if (value === '0') {
      handleChange(data);
      fnFilterOption(value);
      fnSetValue('Todos');
    }
  };

  // funcion para filtrar desde la vista de la pestaña adquisition

  const selectHandlerDetailProject = (event: any) => {
    const value = event.target.value;

    if (value === 'top100') {
      const topFilter = data?.sort(data => data.amount)?.slice(0, 100);
      handleChange(topFilter);
    } else if (value === '0') {
      handleChange(data);
    } else if (value === 'dateStimated') {
      const dateStimatedFilter = data?.filter(
        data => moment(data?.estimatedPublicationDate).year() === moment(new Date()).year(),
      );
      handleChange(dateStimatedFilter);
    } else if (value === 'dateHiring') {
      const dateStimatedFilter = data?.filter(
        data => moment(data?.estimatedContractDate).year() === moment(new Date()).year(),
      );
      handleChange(dateStimatedFilter);
    }
  };

  return (
    <div className="select-filter-container">
      <span className="select-filter-data-text">{labelValue}</span>
      <select
        data-testid="select"
        value={selectValue}
        className={'select-css select-width-large'}
        onChange={detailProject ? selectHandlerDetailProject : selectHandler}
        disabled={disabled}
      >
        {showAllOption && (
          <option value="0" selected>
            {t('all')}
          </option>
        )}
        {options &&
          options.map(option => (
            <option key={option.value} value={option.value} className="capitalize">
              {t(option.optionLabel)}
            </option>
          ))}
      </select>
    </div>
  );
};
