import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEarlyWarningsFilters } from '../../../hooks/useEarlyWarningsFilters';
import { FilterConfigValues, IconCode, TabFilterOption } from '../../../models/EarlyWarnings';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import { CLASSIFICATION_OF_ACQUISITION_PLANS } from '../../../services/sessionFiltersIndicatorDetailHelper';
import { EarlyWarningsFilters as ClassificationOfAcquisitionPlansEarlyWarningsFilters } from '../EarlyWarningsFilters/EarlyWarningsFilters';
import ClassificationOfAcquisitionPlansTable from './Table/Table';
import { createFilterConfigComponentPlans } from '../EarlyWarningsFilters/Util';
import { showEarlyWarning } from '../PortfolioDetailTablesUtil';
import { handleLoading } from '../../utils';
import './ClassificationOfAcquisitionPlans.scss';
import { ReactComponent as IconAlertMulyple } from '../../../assets/icons/u_alert-multyple.svg';
import { ReactComponent as IconAlertExclamation } from '../../../assets/icons/u_alert-exclamacion.svg';
import { ReactComponent as IconAlertCheck } from '../../../assets/icons/u_alert-check.svg';
import { useTranslation } from 'react-i18next';

const filterConfigValues: FilterConfigValues[] = [
  { id: 0, filterIcon: IconCode.GREEN, filterLabel: 'satisfactory' },
  { id: 1, filterIcon: IconCode.YELLOW, filterLabel: 'last_alert' },
  { id: 2, filterIcon: IconCode.RED, filterLabel: 'filter_problem' },
];
const filterConfigComponent: TabFilterOption[] = createFilterConfigComponentPlans(filterConfigValues);

const defaultSessionFilterObject = {
  filterConfigState: filterConfigComponent,
  changeProjectType: true,
};

export const ClassificationOfAcquisitionPlans = () => {
  const dispatch = useDispatch();
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { filterConfigState, handleEarlyWarningsFilter, handleProjectsQuantities } = useEarlyWarningsFilters({
    sessionKey: CLASSIFICATION_OF_ACQUISITION_PLANS,
    defaultSessionFilterObject,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch]);

  useEffect(() => {
    if (!sessionStorage.getItem(CLASSIFICATION_OF_ACQUISITION_PLANS)) {
      sessionStorage.setItem(CLASSIFICATION_OF_ACQUISITION_PLANS, JSON.stringify(defaultSessionFilterObject));
    }
  }, []);

  const renderIcon = calification => {
    if (calification < 0.36) {
      return <IconAlertMulyple />;
    } else if (calification > 0.35 && calification <= 0.7) {
      return <IconAlertExclamation />;
    } else {
      return <IconAlertCheck />;
    }
  };
  return (
    <div>
      <div className="filters-detail">
        <ClassificationOfAcquisitionPlansEarlyWarningsFilters
          filterSet={filterConfigState}
          handleFilter={handleEarlyWarningsFilter}
        />
      </div>
      <div className="head-clasification">
        <div className="clasification-text">
          <p>{t('new_portfolio_rating')}</p>
          <h4>
            {renderIcon(currentIndicator.detailExtraProps.totalWeight)} {currentIndicator.detailExtraProps.totalWeight}
          </h4>
        </div>
      </div>
      <div>
        <ClassificationOfAcquisitionPlansTable
          projectsQuantities={handleProjectsQuantities}
          earlyWarningsTab={[
            filterConfigState[0].selected,
            filterConfigState[1].selected,
            filterConfigState[2].selected,
          ]}
          loading={loading}
        />
      </div>
    </div>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {

  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
