import React, { useEffect, useState } from 'react';
import { getProjectsSimpleTableIndicator } from '../../../redux/actions/indicatorProjects';
import AcquisitionsMilestonesPendingTable from './Table/Table';
import './AcquisitionsMilestonesPending.scss';
import { ExcelDownloadButton } from '../../Util/ExcelDownloadButton/ExcelDownloadButton';
import { ReactComponent as IconAlertExclamation } from '../../../assets/icons/u_alert-exclamacion.svg';
import FiltersCardsProcess from './FiltersCardsProcess';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SelectFilters } from '../../Util/SelectFilters/SelectFilters';
import { handleLoading } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTable from '../../../hooks/useTable';
import useDataNormalize from '../../../hooks/useDataNormalize';

export const AcquisitionsMilestonesPending = () => {
  const [loading, setLoading] = useState(false);
  const { projects } = useTable();
  const [dataFilter, setDataFilter] = useState<any>(projects);
  const [filterOptionDate, setFilterOptionDate] = useState<any>();
  const [defaultValue, setDefaultValue] = useState<string>();
  const { currentIndicator } = useSelector<any, any>(state => state);
  const { projectTypeFilterOnDetail } = useSelector<any, any>(state => state.indicatorDetailFilter);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const headers = ['last_project', 'last_operation', 'new_id_process', 'new_description_process', 'new_estimated_amount', 'last_type', 'last_methodo', 'last_revision', 'state', 'new_estimated_publication_date', 'new_actual_publication_date', 'new_estimated_hiring_date'];
  const selectedKeys = ['code', 'loanNumber', 'field1', 'field2', 'field3', 'field4', 'field5', 'field6', 'field7', 'field8', 'field9', 'field10'];

  useEffect(() => {
    getFilteredSimpleIndicators(dispatch, currentIndicator.id, currentIndicator.indicatorKey, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndicator.id, dispatch, projectTypeFilterOnDetail]);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  const optionsFilter = [{ value: 'top100', optionLabel: 'new_top' }];

  const data = useDataNormalize(headers, selectedKeys, dataFilter);

  return (
    <section className="acquisitions-milestones">
      <div className="filter-cards-process">
        <FiltersCardsProcess
          fndataFilter={setDataFilter}
          dataFilter={dataFilter}
          data={projects}
          filterOption={filterOptionDate}
          fnSetValue={setDefaultValue}
        />
      </div>
      <div className="head-select">
        <div className="filter-select">
          <SelectFilters
            labelValue={t('new_youre_watching')}
            options={optionsFilter}
            dataFilter={dataFilter}
            handleChange={setDataFilter}
            fnFilterOption={setFilterOptionDate}
            data={projects}
            selectValue={defaultValue ? defaultValue : null}
            fnSetValue={setDefaultValue}
          />
        </div>
        <div>
          <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
        </div>
      </div>
      <div>
        <AcquisitionsMilestonesPendingTable dataTable={dataFilter} />
      </div>
    </section>
  );
};

const getFilteredSimpleIndicators = (d, currentIndicatorId, currentIndicatorKey, setLoading) => {
  return handleLoading(() => d(getProjectsSimpleTableIndicator(currentIndicatorId, currentIndicatorKey)), setLoading);
};
