import { AdvanceProps } from '../components/ProjectDetail/Advancev2/Advance';
import { useTranslation } from 'react-i18next';
import TemporaryAdvance from '../models/TemporaryAdvance';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectTabValue } from '../redux/actions/projectTabValue';
import { useEffect, useState } from 'react';
import { getProjectBaseInformation } from '../api/projectApi';
import Project from '../models/Project';
import { renderDateOrSlash } from '../services/stringUtil';
import moment from 'moment';

export const useMapToExecutionPeriodAdvance = (temporaryAdvance: TemporaryAdvance): AdvanceProps => {
  const [baseInfo, setBaseInfo] = useState<any>();
  const currentProject = useSelector<any, Project>(state => state.currentProject);

  const { t } = useTranslation();

  const tPrefix = 'TEMPORARY_ADVANCE';
  const measurementUnit = t('months');
  const measurementUnitSingular = t('month');
  const subtitleAmount = temporaryAdvance.totalProjectDuration;
  const completedAmount = temporaryAdvance.temporaryAdvanceMade;
  const pendingAmount = temporaryAdvance.pendingAdvance ? Math.abs(temporaryAdvance.pendingAdvance) : '';
  const footerOneAmount = temporaryAdvance.totalProjectDuration;
  const footerTwoAmount = temporaryAdvance.totalProjectDuration;
  const dispatch = useDispatch();

  const originalDisbursementDate = moment(baseInfo?.originalDisbursementDate).utc().format('YYYY-MM-DD');
  const contractDate = moment(baseInfo?.contractDate).utc().format('YYYY-MM-DD');

  const totalOriginal = moment(originalDisbursementDate).diff(contractDate, 'months');

  const loadBaseInformation = async () => {
    const response = await getProjectBaseInformation(currentProject.id);
    // const bidMembersSorted = sortByRoles(response.projectTeams);
    // setBidMembers(bidMembersSorted);
    // const execUnit = response.projectTeams.filter(x => !x.bidMember);
    // setExecUnit(execUnit);
    setBaseInfo(response);
    // dispatch(resolveProjectReportSections('baseInfo'));
  };

  useEffect(() => {
    if (currentProject && currentProject.id > 0 && baseInfo == null) {
      loadBaseInformation();
      // getRolesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  const buildMeasurementUnit = (amount: number | undefined): string => {
    if (!amount) {
      return '';
    }
    return amount === 1 ? measurementUnitSingular : measurementUnit;
  };

  const handleSeeMoreClick = () => {
    dispatch(setProjectTabValue(0));
    const targetSection = document.getElementById('project-tabs');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const advanceProps: AdvanceProps = {
    title: t(`temporary_advance_title`),
    subtitle: `${t('total').toUpperCase()} ${subtitleAmount} ${buildMeasurementUnit(subtitleAmount)}`,
    completedLabel: t(`temporary_advance_completed_label`),
    pendingLabel: t(`temporary_advance_pending_label`),
    percent: temporaryAdvance.percentTemporaryAdvanceMade || 1,
    completed: `${completedAmount} ${buildMeasurementUnit(completedAmount)}`,
    pending: pendingAmount ? `${pendingAmount} ${buildMeasurementUnit(pendingAmount)}` : '',
    completedOne: `${renderDateOrSlash(baseInfo?.contractDate)}`, //TODO
    pendingOne: `${renderDateOrSlash(baseInfo?.lastExtensionApprobedDate)}`, //TODO
    completedTwo: t(`temporary_advance_completed_two`),
    pendingTwo: t(`temporary_advance_pending_two`),
    footerOne: `${t(`temporary_advance_footer_one`)}: ${totalOriginal} ${buildMeasurementUnit(totalOriginal)}`,
    footerTwo: `${t(`temporary_advance_footer_two`)}: ${
      footerTwoAmount && footerTwoAmount - totalOriginal
    } ${buildMeasurementUnit(footerTwoAmount)}`,
    handleSeeMoreClick: handleSeeMoreClick,
  };

  return advanceProps;
};
