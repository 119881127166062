import React, { useEffect, useState } from 'react';
import '../../Acquisitions.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ReactComponent as IconSort } from '../../../../../../assets/icons/u_sort.svg';
import { Pagination } from '@material-ui/lab';
import { ProjectsListSkeleton } from '../../../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import PaginationTable from '../../../../../PortfolioDetail/PaginationTable/PaginationTable';
import { useTranslation } from 'react-i18next';
import usePaginationSort from '../../../../../../hooks/usePaginationSort';
import { ExcelDownloadButton } from '../../../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../../../hooks/useDataNormalize';
import { renderDateOrSlash } from '../../../../../../services/stringUtil';
import NumberFormat from 'react-number-format';
import { sortTablesByType } from '../../../../../../services/sortsUtil';

const headerValue = [
  {
    headerValue: 'new_id_process',
    headerCode: 'processId',
  },
  {
    headerValue: 'description',
    headerCode: 'description',
  },
  {
    headerValue: 'state',
    headerCode: 'stateName',
  },
  {
    headerValue: 'amount',
    headerCode: 'amount',
  },
  {
    headerValue: 'new_type_method_revision',
    headerCode: 'typeName',
  },
  {
    headerValue: 'new_estimated_publication_date',
    headerCode: 'estimatedPublicationDate',
  },
  {
    headerValue: 'new_actual_publication_date',
    headerCode: 'actualPublicationDate',
  },
  {
    headerValue: 'new_estimated_hiring_date',
    headerCode: 'estimatedContractDate',
  },
  {
    headerValue: 'new_actual_hiring',
    headerCode: 'actualContractDate',
  },
];

const headers = ['new_id_process', 'description', 'supervision_status', 'amount', 'last_type', 'last_method', 'last_revision', 'new_estimated_publication_date', 'new_actual_publication_date', 'new_estimated_hiring_date', 'new_actual_hiring'];
const selectedKeys = ['processId', 'description', 'stateName', 'amount', 'typeName', 'methodName', 'revision', 'estimatedPublicationDate', 'actualPublicationDate', 'estimatedContractDate', 'actualContractDate'];

export default function AcquisitionTable({ classificationData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortAsc, setSortAsc] = React.useState(true);

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: classificationData,
    defaultSortCriteria: 'processId',
    pageCount: rowsPerPage,
  });

  useEffect(() => { }, [classificationData]);

  const sortTable = (data, keyToSort, fieldType) => {
    sortTablesByType(data, sortAsc, keyToSort, fieldType);
    setSortAsc(!sortAsc);
  };

  const validateEstimationDate = (estimationDate, actualPublicationDate) => {
    let currentDate = new Date();
    if (new Date(estimationDate) < new Date(currentDate) && !actualPublicationDate) {
      return 'alerColorAdquisition';
    }
    return '';
  };

  const validateHiringDate = (estimationDate, actualContractDate) => {
    let currentDate = new Date();

    if (new Date(estimationDate) < new Date(currentDate) && !actualContractDate) {
      return 'alerColorAdquisition';
    }
    return '';
  };
  useEffect(() => {
    setIsLoading(true);
    // Simulating data fetching
    setTimeout(() => {
      // setData(rows);
      setIsLoading(false);
    }, 2000);
  }, []);
  let dataExcel = useDataNormalize(headers, selectedKeys, classificationData);

  return (
    <div className="indicator-detail-table-container">
      <TableContainer>
        {isLoading ? (
          <ProjectsListSkeleton />
        ) : (
          <>
            <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={dataExcel} />
            <div className="border-table">
              <Table>
                <TableHead>
                  <TableRow>
                    {headerValue.map(value => {
                      return (
                        <TableCell onClick={() => sortTable(currentList, value.headerCode, 'string')}>
                          <div className="text-head">
                            <p>{t(value.headerValue)}</p> <IconSort className="sortIcon" />
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentList?.map(value => (
                    <TableRow key={value.id}>
                      <TableCell style={{ width: '11%' }}>{value.processId}</TableCell>
                      <TableCell className="link-project">{value.description}</TableCell>
                      <TableCell>{value.stateName}</TableCell>
                      <TableCell>
                        <NumberFormat
                          value={value.amount}
                          displayType={'text'}
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          decimalScale={2}
                        />
                      </TableCell>
                      <TableCell>{`${value.typeName + '/' + value.methodName + '/' + value.revision}`}</TableCell>
                      <TableCell
                        className={validateEstimationDate(value.estimatedPublicationDate, value.actualPublicationDate)}
                      >
                        {' '}
                        {renderDateOrSlash(value.estimatedPublicationDate)}{' '}
                      </TableCell>
                      <TableCell>{renderDateOrSlash(value.actualPublicationDate)}</TableCell>
                      <TableCell
                        className={validateHiringDate(value.estimatedPublicationDate, value.actualContractDate)}
                      >
                        {' '}
                        {renderDateOrSlash(value.estimatedContractDate)}
                      </TableCell>
                      <TableCell>{renderDateOrSlash(value.actualContractDate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </TableContainer>
      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={classificationData?.length} />
        <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
      </div>
    </div>
  );
}
