import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CloseCrossButton from '../../Buttons/CloseCrossButton/CloseCrossButton';
import { tabStyles } from '../../../Projects/ProjectsAll/ProjectsAll.Style';
import { disableEscKey } from '../Util';
import './AddIndicators.scss';
import SubmitButton from '../../../Util/Buttons/SubmitButton/SubmitButton';
import { ReactComponent as SearchIcon } from '../../../../assets/search_blue.svg';

import AddIndicatorCard from './Card/AddIndicatorCard';
import { TabPanel } from './AddIndicatorsUtil';
import { getIndicatorsList, updateSelectedIndicators } from '../../../../redux/actions/indicators';
import { setRequestStatus } from '../../../../redux/actions/requestStatus';
import { RequestStatus } from '../../../../models/RequestStatus';
import { isMobile, isIE11 } from '../../../../services/booleanUtil';
import { AddIndicatorsMobile } from './AddIndicatorsMobile';

import { trackActionClicked } from '../../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../../models/TrackedActionsEnum';
import BaseModal from '../BaseModal';

const customStyles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    overflowY: 'auto',
  },
  content: {
    top: isMobile() ? '0%' : '10%',
    left: isMobile() ? '0%' : '6%',
    right: isMobile() ? '0%' : '6%',
    bottom: 'auto',
    borderRadius: isMobile() ? '0px' : '15px',
    padding: 0,
    overflow: 'auto',
    marginBottom: isMobile() ? '60px' : '100px',
  },
};

type Props = {
  isOpen: boolean;
  closeModal: any;
};

const groupIndicators = indicators => {
  const auxIndicators: any = [];

  indicators.forEach(item => {
    item.indicators.forEach(indicator => {
      if (indicator.id !== 15) auxIndicators.push(indicator);
    });
  });

  return auxIndicators.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
};

const AddIndicators = (props: Props) => {
  const { isOpen, closeModal } = props;
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const indicators = useSelector<any, any[]>(state => state.indicators);
  const [selectedIndicators, setSelectedIndicators] = React.useState<number[]>([]);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [foundIndicators, setFoundIndicators] = React.useState<any>([]);
  const { t } = useTranslation();

  const allIndicatorsFromState = useSelector<any, any[]>(state => state.allIndicators);
  const requestStatus = useSelector<any, any>(state => state.requestStatus);
  const dispatch = useDispatch();

  const searchTab = { name: 'Search', indicators: foundIndicators, isSearch: true };
  const allIndicators = [searchTab, ...allIndicatorsFromState];
  const allIndicatorsForSearch = groupIndicators(allIndicatorsFromState);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    setFoundIndicators([]);
  };

  const submit = () => {
    dispatch(updateSelectedIndicators(selectedIndicators, t));
    trackActionClicked(TrackedActions.indicatorCreated);
  };

  const addOrRemoveIndicator = id => {
    let newArr: number[] = [];
    if (selectedIndicators.includes(id)) {
      newArr = selectedIndicators.filter((value: number) => value !== id);
    } else {
      newArr = [...selectedIndicators, id];
    }

    setSelectedIndicators(newArr);
  };

  const handleSearch = event => {
    if (event.target.value.length > 2) {
      setSearchInput(event.target.value.toLowerCase());
    } else {
      setSearchInput('');
    }
  };

  const searchIndicators = () => {
    const foundIndicators = allIndicatorsForSearch.filter(indicator => {
      return (
        indicator.title.toLowerCase().includes(searchInput) || indicator.description.toLowerCase().includes(searchInput)
      );
    });

    setFoundIndicators(foundIndicators);
  };

  const handleCloseModal = () => {
    setSearchInput('');
    setFoundIndicators([]);
    closeModal();
  };

  React.useEffect(() => {
    setSelectedIndicators(indicators.map(i => i.id));
  }, [indicators]);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(getIndicatorsList());
      setSelectedTab(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    if (requestStatus === RequestStatus.Success) {
      closeModal();
      dispatch(setRequestStatus(RequestStatus.Default));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus]);

  React.useEffect(() => {
    if (searchInput !== '') {
      searchIndicators();
    } else {
      setFoundIndicators([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const modalsDesktopParams = {
    isOpen,
    requestStatus,
    closeModal,
    selectedTab,
    allIndicators,
    selectedIndicators,
    addOrRemoveIndicator,
    handleChange,
    handleSearch,
    handleCloseModal,
    submit,
    t,
  };

  const modalsMobileParams = {
    isOpen,
    requestStatus,
    closeModal,
    allIndicators,
    selectedIndicators,
    addOrRemoveIndicator,
    handleSearch,
    handleCloseModal,
    submit,
    t,
  };

  return !isMobile() ? desktopModal(modalsDesktopParams) : mobileModal(modalsMobileParams);
};

export default AddIndicators;

export const desktopModal = modalsDesktopParams => {
  const {
    isOpen,
    requestStatus,
    closeModal,
    selectedTab,
    allIndicators,
    selectedIndicators,
    addOrRemoveIndicator,
    handleChange,
    handleCloseModal,
    handleSearch,
    submit,
    t,
  } = modalsDesktopParams;
  const classes = makeStyles(tabStyles)();
  const tabClasses = { root: classes.root, selected: classes.selected };
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={
        requestStatus === RequestStatus.Loading
          ? () => {
              return false;
            }
          : e => {
              disableEscKey(e, closeModal);
            }
      }
      style={customStyles}
    >
      <div className="modal-add-indicators">
        <div className="close-modal">
          <CloseCrossButton handleClick={handleCloseModal} />
        </div>
        <div className="title-container">
          <span className="title">{t('select_your_indicators')}</span>
          <span className="subtitle">{t('monitor_portfolio')}</span>
        </div>
        <div className="tabs-container">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            classes={{
              indicator: classes.indicatorColor,
              root: classes.root,
            }}
            variant="standard"
            aria-label="full width tabs example"
          >
            {allIndicators &&
              allIndicators.map(menu => (
                <Tab
                  key={menu.name}
                  label={!menu.isSearch && menu.name}
                  icon={menu.isSearch && <SearchIcon />}
                  className={menu.isSearch ? 'searchTab' : 'tabs-container'}
                />
              ))}
          </Tabs>
        </div>
        <div className="indicators-search-container">
          {selectedTab === 0 && (
            <input className="indicators-search-input" onChange={handleSearch} placeholder={t('search')} />
          )}
        </div>
        <div className="cards-container">
          {allIndicators &&
            allIndicators.map((menu, index) => (
              <TabPanel key={menu.name} value={selectedTab} index={index}>
                <div className="cards-inner-container" style={isIE11() ? { width: '70vw' } : {}}>
                  {menu.indicators &&
                    menu.indicators.map(indicator => (
                      <>
                        {indicator.id !== 15 && (
                          <AddIndicatorCard
                            key={indicator.id}
                            card={indicator}
                            isSelected={selectedIndicators.includes(indicator.id)}
                            setSelected={id => {
                              addOrRemoveIndicator(id);
                            }}
                            showDescription={true}
                          />
                        )}
                      </>
                    ))}
                </div>
              </TabPanel>
            ))}
        </div>
        <div className="save-container">
          {selectedIndicators.length === 0 && <span className="warning">{t('at_least_one_indicator')}</span>}
          <SubmitButton
            buttonState={requestStatus}
            handleClick={submit}
            customText={t('save_changes')}
            disabled={selectedIndicators.length === 0}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export const mobileModal = modalsMobileParams => {
  const {
    isOpen,
    requestStatus,
    closeModal,
    allIndicators,
    selectedIndicators,
    addOrRemoveIndicator,
    submit,
    t,
    handleSearch,
    handleCloseModal,
  } = modalsMobileParams;
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={
        requestStatus === RequestStatus.Loading
          ? () => {
              return false;
            }
          : e => {
              disableEscKey(e, closeModal);
            }
      }
      style={customStyles}
    >
      <div className="modal-add-indicators">
        <div className="close-modal">
          <CloseCrossButton buttonClass={'close-back-button'} handleClick={handleCloseModal} />
        </div>
        <div className="title-container">
          <span className="title">{t('select_your_indicators')}</span>
          <span className="subtitle">{t('monitor_portfolio')}</span>
        </div>
        <div className="tabs-container">
          <AddIndicatorsMobile
            allIndicators={allIndicators}
            selectedIndicators={selectedIndicators}
            addOrRemoveIndicator={addOrRemoveIndicator}
            handleSearch={handleSearch}
          />
        </div>

        <div className="save-container">
          {selectedIndicators.length === 0 && <span className="warning">{t('at_least_one_indicator')}</span>}
          <SubmitButton
            buttonState={requestStatus}
            handleClick={submit}
            customText={t('save_changes')}
            disabled={selectedIndicators.length === 0}
          />
        </div>
      </div>
    </BaseModal>
  );
};
